.input-wrapper:not(:last-child) {
  margin-bottom: 10px;
}

.input-error {
  color: #d6150f;
  margin: 4px 0;
  font-weight: 600;
}

.exclamation-circle {
  margin-right: 5px;
  background-color: #d6150f;
  color: #fff;
  width: 14px;
  height: 14px;
  display: inline-block;
  border-radius: 14px;
  line-height: 14px;
  font-size: 12px;
  text-align: center;
  vertical-align: middle;
  margin-bottom: 2px;
}
