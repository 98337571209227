
.viewer-item {
  cursor: move;
  position: relative;
}

.viewer-item.viewer-item-fixed {
  cursor: default;
}

.viewer-item .viewer-item-left {
  position: absolute;
  top: -10px;
  left: -10px;
  width: 20px;
  bottom: -10px;
  cursor: w-resize;
}

.viewer-item .viewer-item-right {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 20px;
  bottom: -10px;
  cursor: e-resize;
}

.viewer-item .viewer-item-top {
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  height: 20px;
  cursor: n-resize;
}

.viewer-item .viewer-item-bottom {
  position: absolute;
  bottom: -10px;
  left: -10px;
  right: -10px;
  height: 20px;
  cursor: s-resize;
}
